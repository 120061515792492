// Specifies size of the modal
.bx--modal {
  width: 555px;
  height: 376px;
  max-height: 376px;
  position: fixed;
  left: calc(
    50% - 278px
  ); // Places the modal in the center of the page: calc(50% - {half the width of the modal in px})
  top: calc(
    50% - 188px
  ); // Places the modal in the center of the page: calc(50% - {half the height of the modal in px})
  align-items: baseline;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: var(--white);
}

.bx--link {
  cursor: pointer;
}

.bx--modal .bx--modal-container .bx--modal-close__icon {
  width: 30px !important;
  height: 28px !important;
  bottom: 4px;
  right: 4px;
}

.bx--modal .bx--modal-close {
  padding: 0;
}

// Overrides the default size and color of the carbon component
.bx--modal-container {
  width: 555px;
  max-height: 376px;
  height: 376px;
  background-color: var(--white);
}

// Overrides the default padding of the carbon component
.bx--modal-container .bx--modal-content--with-form {
  padding: 0 42px 0 0;
}

// Overrides the default color and size of the carbon component content specs
.bx--modal-content {
  width: 555px;
  height: 376px;
  background-color: var(--white);
  margin: 0;
}

// Overrides the default header of the carbon component
.bx--modal-header {
  width: 555px;
  background-color: var(--white);
  padding-top: 0;
  margin-bottom: 0;
  height: 0;
}

.bx--modal.is-visible .bx--modal-container,
.bx--modal .bx--modal-container {
  transform: none;
}

.bx--modal-close:focus {
  border: none;
}

// Specifies text align and margins to place the modal content in the right place
div.therapy-container {
  margin: 60px 0 60px 74px;
  text-align: left;
}

// First row has different margin specs from the following lines, they are specified here
div.drug-target-margins {
  background-color: var(--white);
  text-align: left;
  display: flex;
  margin: 24px 0 0 3px;
}

// Configure margins between each row
div.therapy-row-margins {
  background-color: var(--white);
  text-align: left;
  display: flex;
  margin: 16px 0 0 3px;
}

// Specifies icon placement next to the drug title
.drug-icon {
  width: 20px;
  height: 20px;
  margin: 0 6px 0 0;
  top: 4px;
  object-fit: contain;
  position: relative;
}

// Title has different font specs from the following lines, they are specified here
span.drug-title {
  width: 103px;
  height: 16px;
  margin: 2px 0 2px 0;
  font-family: Inter;
  font-size: 16px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--cool-gray-100-v-2-1);
}

// Specifies font info for each label
p.therapy-modal-label {
  min-width: 144px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.32px;
  text-align: left;
  color: var(--gray-100-v-2-1);
}

// Specifies font info for each content box
p.therapy-modal-content {
  max-width: 292px;
  word-wrap: break-word;
  font-family: Inter;
  font-size: 14px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  color: var(--gray-100-v-2-1);
}

// Shadow top, left, bottom, right values are all set to 0 to fill the whole page
div.background-shadow {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 8500;
  position: fixed;
  background-color: rgba(33, 39, 42, 0.73);
}
