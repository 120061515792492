/* somethingwrong.component.scss
*
*  This .css file contains code for the styling of Something Went Wrong View
*/
.center {
  text-align: center;
  padding-top: 250px;

  .something-went-wrong {
    width: 343px;
    height: 36px;
    margin: 0px 41px 12px 700px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: Inter;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: var(--gray-100-v-2-1);
  }
  .center {
    text-align: center;
  }
  .we-are-sorry {
    width: 426px;
    height: 40px;
    margin: 12px 0 16px;
    margin-left: auto;
    margin-right: auto;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.16px;
    text-align: center;
    color: var(--gray-70-v-2-1);
  }
  .bgcol:hover {
    background-color: var(--blue-90-v-2-1);
  }
  .bgcol {
    background-color: #0043ce;
    margin-left: auto;
    margin-right: auto;
  }
}
/* 
* EOF
*/
