.therapy-table {
  position: relative;
  border-collapse: collapse;
  padding-bottom: 40px;

  table {
    width: 100%;
  }

  th {
    height: 18px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.16px;
    color: var(--gray-100-v-2-1);
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dde1e6;

    p {
      height: 18px;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: 0.16px;
      color: var(--gray-100-v-2-1);
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: left;
    }
  }

  tr {
    border-bottom: 1px solid #dde1e6;
  }

  td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 17px;

    height: 18px;
    margin: 14px 0 0 16px;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.16px;
    color: #525252;

    .label-container {
      display: inline-block;
      padding: 3px 8px 3px 8px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      font-family: Inter;
    }

    #very-high-label {
      border-radius: 12px;
      background-color: #a7f0ba;
      text-align: center;
    }

    #high-label {
      border-radius: 12px;
      background-color: #d1e592;
      text-align: center;
    }

    #medium-label {
      border-radius: 12px;
      background-color: #fddc69;
      text-align: center;
    }

    #low-label {
      border-radius: 12px;
      background-color: #fbd9a1;
      text-align: center;
    }

    #very-low-label {
      border-radius: 12px;
      background-color: #ffd7d9;
      text-align: center;
    }
  }

  #therapy-name {
    width: 20%;
  }

  #therapy-drug-class {
    width: 30%;
  }

  #therapy-RSMT-value {
    width: 15%;
  }

  #therapy-trial-phase {
    width: 15%;
  }

  #therapy-ranking {
    width: 20%;
  }

  #see-more {
    width: 90%;
    height: 18px;
    position: absolute;
    bottom: 0;
    right: 0;
    font-family: Inter;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.16px;
    text-align: right;
    color: var(--blue-70-v-2-1);
    cursor: pointer;
  }

  #see-more a:hover {
    width: 90%;
    height: 18px;
    font-family: Inter;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.16px;
    text-align: right;
    color: var(--blue-70-v-2-1);
    cursor: pointer;
    text-decoration: underline;
  }
}
