//Removes default NavLink underline
@import '../../styles/global.scss';

a {
  text-decoration: none;
}

.bx--header {
  background-color: white;
  border-color: var(--gray-30-v-2-1);
  height: 48px;
}

a.bx--header__name {
  color: var(--blue-70-v-2-1);
  padding: 0;
}

a.bx--header__name:hover {
  color: var(--blue-70-v-2-1);
  padding: 0;
}

a.bx--header__menu-item {
  color: var(--gray-70-v-2-1);
  padding-bottom: 12px;
  padding-top: 12px;
}

a.bx--header__menu-item:hover {
  color: var(--gray-70-v-2-1);
  background-color: white;
}

// Makes the current route's tab underlined
// "selected" must == SELECTED_STRING in NavHeader.tsx
a.selected li a.bx--header__menu-item {
  color: var(--gray-70-v-2-1);
  padding: 11.5px 18px 13.5px 18px;
  margin-top: 4px;
  border-color: var(--blue-70-v-2-1);
  border-top: 0px white;
  border-left: 0px white;
  border-right: 0px white;
}

div.bx--assistive-text {
  overflow: hidden;
}

li {
  list-style: none;
}

.vertical-divider {
  width: 1px;
  height: 24px;
  margin: 12px 16px 12px 26px;
  background-color: #3d3d3d;
}

//oveflowmenu styling
div .bx--overflow-menu {
  height: 47px;
  width: 47px;
}
div .bx--overflow-menu:hover {
  background-color: #eff1f6;
  background-color: #d5dae0;
}

div .bx--overflow-menu:focus {
  outline: 0px transparent;
}

div .bx--overflow-menu.bx--overflow-menu--open {
  box-shadow: none;
}

div .bx--overflow-menu.bx--overflow-menu--open {
  background-color: #eff1f6;
  outline: 0px transparent;
}

.user-logout-icon {
  float: right;
  color: black;
}

.user-logout-icon:hover {
  color: #525252;
  background-color: transparent;
}

.oncologist-header {
  width: 100%;
  height: 18px;
  text-align: right;
  margin: 15px 16px 15px 10px;
  font-family: $inter;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  color: #161616;
}

div .signout-item {
  font-family: $inter;
  margin: 0px;
}

div .signout-item:focus {
  outline-color: transparent;
}

div .bx--overflow-menu-options {
  top: 48px !important; //overwrite the unwanted style from carbon component
  position: fixed !important;
  right: 0 !important;
  left: auto !important;
  padding: 16px 0px 16px 0px;
  // z-index:1000;
  text-align: center;
  width: 256px;
  height: 64px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: var(--cool-gray-10-v-2-1);
}

div .bx--overflow-menu-options__option:hover {
  background-color: #d5dae0;
}
div .bx--overflow-menu-options[data-floating-menu-direction='bottom']::after {
  top: 0;
}
