.card {
  max-height: 945px;
  margin-bottom: 199px;
  float: left;
  width: 49%;
  padding: 0 0 70px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: var(--white);
}
.header {
  height: 62px;
  margin: 0 0 23px;
  padding: 20px 47px;
  background-color: var(--cool-gray-10-v-2-1);
}
.title {
  float: left;
  height: 22px;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--black);
}

.title-link {
  float: right;
  height: 18px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  text-align: right;
}

.sygnal-risk {
  margin: 38px 32px 70px 32px;
  text-align: left;
}

.sygnal-risk-score {
  margin-left: 16px;
  margin-bottom: 28px;
  height: 18px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Inter';
  color: var(--gray-100-v-2-1);
}

//the colors based on each risk level, should change depending on patient info
.risk-profile-risk-score {
  margin-left: 16px;
  margin-bottom: 12px;
  text-align: left;
  font-size: 25px;
  font-family: 'IBM Plex Mono';
  font-weight: normal;
}

.risk-score.Extreme-color {
  color: #ce001f;
}

.risk-score.High-color {
  color: #e66d18;
}

.risk-score.Low-color {
  color: #229338;
}

.risk-percentile-box {
  margin-left: 16px;
  margin-bottom: 50px;
  display: inline-block;
  height: 34px;
  border: solid 1px var(--cool-gray-20-v-2-1);
  border-left-width: 2px;
}

.risk-percentile-box.Extreme-color {
  border-left-color: #ce001f;
}

.risk-percentile-box.High-color {
  border-left-color: #e66d18;
}

.risk-percentile-box.Low-color {
  border-left-color: #229338;
}

.risk-percentile-box-text {
  padding: 8px 16px 8px 16px;
  height: 16px;
  font-size: 12px;
  font-family: 'Inter';
  color: #171717;
}

.percentile-ordinal {
  font-size: 8px;
  color: #171717;
}

.risk-profile-card-histogram {
  margin-left: 8px;
  height: 322px;
}

.line {
  margin: 0px;
  width: 100%;
  height: 1px;
  border: solid 1px var(--cool-gray-20-v-2-1);
}

.abnormalities-present {
  margin-left: 16px;
  margin-top: 30.5px;
}

.abnormalities-present h1 {
  height: 18px;
  margin-bottom: 20px;
  text-align: left;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  color: var(--gray-100-v-2-1);
}

.abnormalities-tag {
  margin-bottom: 29.5px;
  float: left;
  height: 16px;
  margin-right: 10px;
  background-color: #d0e2ff;
  font-size: 12px;
  color: var(--gray-100-v-2-1);
}

.median-progression {
  margin-top: 30.5px;
  margin-left: 16px;
}

.median-progression-title {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: left;
  height: 18px;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  color: var(--gray-100-v-2-1);
}

.months-box-text {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
}

.months-box.Extreme-color {
  border-left-color: #ce001f;
}

.months-box.High-color {
  border-left-color: #e66d18;
}

.months-box.Low-color {
  border-left-color: #229338;
}

.months-box {
  margin-bottom: 10px;
  display: inline-block;
  text-align: left;
  border: solid 1px var(--cool-gray-20-v-2-1);
  border-left-width: 2px;
}

.months-box h1 {
  margin: 9px 16px 8px 14px;
  height: 16px;
  font-size: 12px;
  color: #171717;
}

.median-progression p {
  text-align: left;
  float: none;
  height: 40px;
  font-size: 12px;
  font-family: 'Inter';
  color: #171717;
}
