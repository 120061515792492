@import url('https://fonts.googleapis.com/css?family=Inter');
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono');

$inter: 'Inter', sans-serif;

:root {
  --black: #000000;
  --white: #ffffff;
  --magenta-60: #cf256f;
  --red-100-v-2-1: #2d0709;
  --red-90-v-2-1: #520408;
  --red-80-v-2-1: #750e13;
  --red-70-v-2-1: #a2191f;
  --red-60-v-2-1: #da1e28;
  --red-50-v-2-1: #fa4d56;
  --red-40-v-2-1: #ff8389;
  --red-30-v-2-1: #ffb3b8;
  --red-20-v-2-1: #ffd7d9;
  --red-10-v-2-1: #fff1f1;
  --magenta-100-v-2-1: #2a0a18;
  --magenta-90-v-2-1: #510224;
  --magenta-80-v-2-1: #740937;
  --magenta-70-v-2-1: #9f1853;
  --magenta-60-v-2-1: #d02670;
  --magenta-50-v-2-1: #ee5396;
  --magenta-40-v-2-1: #ff7eb6;
  --magenta-30-v-2-1: #ffafd2;
  --magenta-20-v-2-1: #ffd6e8;
  --magenta-10-v-2-1: #fff0f7;
  --purple-100-v-2-1: #1c0f30;
  --purple-90-v-2-1: #31135e;
  --purple-80-v-2-1: #491d8b;
  --purple-70-v-2-1: #6929c4;
  --purple-60-v-2-1: #8a3ffc;
  --purple-50-v-2-1: #a56eff;
  --purple-40-v-2-1: #be95ff;
  --purple-30-v-2-1: #d4bbff;
  --purple-20-v-2-1: #e8daff;
  --purple-10-v-2-1: #f6f2ff;
  --blue-100-v-2-1: #001141;
  --blue-90-v-2-1: #001d6c;
  --blue-80-v-2-1: #002d9c;
  --blue-70-v-2-1: #0043ce;
  --blue-60-v-2-1: #0f62fe;
  --blue-50-v-2-1: #4589ff;
  --blue-40-v-2-1: #78a9ff;
  --blue-30-v-2-1: #a6c8ff;
  --blue-20-v-2-1: #d0e2ff;
  --blue-10-v-2-1: #edf5ff;
  --cyan-100-v-2-1: #061727;
  --cyan-90-v-2-1: #012749;
  --cyan-80-v-2-1: #003a6d;
  --cyan-70-v-2-1: #00539a;
  --cyan-60-v-2-1: #0072c3;
  --cyan-50-v-2-1: #1192e8;
  --cyan-40-v-2-1: #33b1ff;
  --cyan-30-v-2-1: #82cfff;
  --cyan-20-v-2-1: #bae6ff;
  --cyan-10-v-2-1: #e5f6ff;
  --teal-100-v-2-1: #081a1c;
  --teal-90-v-2-1: #022b30;
  --teal-80-v-2-1: #004144;
  --teal-70-v-2-1: #005d5d;
  --teal-60-v-2-1: #007d79;
  --teal-50-v-2-1: #009d9a;
  --teal-40-v-2-1: #08bdba;
  --teal-30-v-2-1: #3ddbd9;
  --teal-20-v-2-1: #9ef0f0;
  --teal-10-v-2-1: #d9fbfb;
  --green-100-v-2-1: #071908;
  --green-90-v-2-1: #022d0d;
  --green-80-v-2-1: #044317;
  --green-70-v-2-1: #0e6027;
  --green-60-v-2-1: #198038;
  --green-50-v-2-1: #24a148;
  --green-40-v-2-1: #42be65;
  --green-30-v-2-1: #6fdc8c;
  --green-20-v-2-1: #a7f0ba;
  --green-10-v-2-1: #defbe6;
  --cool-gray-100-v-2-1: #121619;
  --cool-gray-90-v-2-1: #21272a;
  --cool-gray-80-v-2-1: #343a3f;
  --cool-gray-70-v-2-1: #4d5358;
  --cool-gray-60-v-2-1: #697077;
  --cool-gray-50-v-2-1: #878d96;
  --cool-gray-40-v-2-1: #a2a9b0;
  --cool-gray-30-v-2-1: #c1c7cd;
  --cool-gray-20-v-2-1: #dde1e6;
  --cool-gray-10-v-2-1: #f2f4f8;
  --gray-100-v-2-1: #161616;
  --gray-90-v-2-1: #262626;
  --gray-80-v-2-1: #393939;
  --gray-70-v-2-1: #525252;
  --gray-60-v-2-1: #6f6f6f;
  --gray-50-v-2-1: #8d8d8d;
  --gray-40-v-2-1: #a8a8a8;
  --gray-30-v-2-1: #c6c6c6;
  --gray-20-v-2-1: #e0e0e0;
  --gray-10-v-2-1: #f4f4f4;
  --warm-gray-100-v-2-1: #171414;
  --warm-gray-90-v-2-1: #272525;
  --warm-gray-80-v-2-1: #3c3838;
  --warm-gray-70-v-2-1: #565151;
  --warm-gray-60-v-2-1: #726e6e;
  --warm-gray-50-v-2-1: #8f8b8b;
  --warm-gray-40-v-2-1: #ada8a8;
  --warm-gray-30-v-2-1: #cac5c4;
  --warm-gray-20-v-2-1: #e5e0df;
  --warm-gray-10-v-2-1: #f7f3f2;
  --orange-40-v-2-1: #ff832b;
  --yellow-30-v-2-1: #f1c21b;
  --overlay-01: rgba(22, 22, 22, 0.5);
  --blue-histogram-light: #c6daff;
}
