.breadcrumb-container {
  height: 52px;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-color: #dde1e6;
  margin-bottom: 35.5px;

  nav {
    margin-left: 112px;
    margin-right: 112px;
  }
}

ol.bx--breadcrumb {
  padding: 20px;
  padding-left: 0px; //overrides the 20px padding
  margin-right: auto;
  margin-left: auto;
  max-width: 99rem;
  font-size: 14px;
}

//the slash
.bx--breadcrumb-item::after {
  color: #100;
}

li.bx--breadcrumb-item a {
  color: #0043ce;
}

.bx--breadcrumb-item--current {
  color: #100;
  pointer-events: none;
}
