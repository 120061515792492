.card {
  width: 50%;
  padding: 0 0 70px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: var(--white);
}

.therapy-name {
  height: 48px;
  padding: 0 0 0 15px;
  margin: 15px 0 15px 16px;
  background-color: var(--white);
}

.therapy-type {
  height: 48px;
  padding: 0 0 0 15px;
  margin: 15px 0 15px 16px;
  background-color: var(--white);
}

.therapy-btn-col {
  height: 48px;
  padding: 0 0 0 15px;
  margin: 15px 0 15px 16px;
  background-color: var(--white);
}

.card {
  float: right;
  width: 49%;
  padding: 0 0 70px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: var(--white);
}

.header {
  height: 62px;
  margin: 0 0 23px;
  padding: 20px 47px;
  background-color: var(--cool-gray-10-v-2-1);
}

.title {
  float: left;
  height: 22px;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--black);
}

.title-link {
  float: right;
  height: 18px;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  text-align: right;
}

.tag-all {
  margin-left: 0;
  height: 24px;
  border-radius: 12px;
}

.tag-very-high {
  background-color: var(--green-20-v-2-1);
}

.tag-high {
  background-color: #d1e592;
}

.tag-medium {
  background-color: #fddc69;
}

.tag-low {
  background-color: #fbd9a1;
}

.tag-very-low {
  background-color: var(--red-20-v-2-1);
}

td.tag-view {
  padding: 17px 0 15px 0;
  border-top: 1px solid var(--cool-gray-20-v-2-1);
}

.table-margins {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 23px;
  width: 100%;
  table {
    width: 100%;
  }
  td {
    height: 48px !important;
    margin: 15px 0 15px 16px !important;
    padding: 0 0 0 15px !important;
    line-height: 1 !important;
    vertical-align: middle;
  }
  th {
    vertical-align: middle;
    border-bottom: 1px solid var(--cool-gray-20-v-2-1);
  }

  tr {
    margin-left: 15px;
    margin-right: 15px;
    padding: 0 0 0 15px;
  }
}
