div.bx--grid {
  padding: 0px;
  margin-top: 28.5px;
}

//all rows
div.bx--row {
  margin: 0px;
  flex-wrap: nowrap;
}

.first-row div {
  margin-bottom: 10px;
}

//all the boxes in the rows
.first-row div,
.second-row div {
  background: var(--blue-10-v-2-1);
  height: 84px; //to be able to take up 2 lines
  margin-right: 10px;
}

#last-column-right-box {
  margin-right: 0px;
}

.grid-box-content {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

//title of box
div.bx--row div p {
  color: var(--cool-gray-70-v-2-1);
  margin-left: 24px;
  margin-right: 20px;
  font-size: 10px;
  text-align: left;
}

//information in boxes
div.bx--row div h1 {
  color: var(--gray-100-v-2-1);
  margin-left: 24px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
