.my-patients {
  font-size: 32px;
  text-align: left;
  font-family: Inter;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--gray-100-v-2-1);
  padding-bottom: 60px;
}

.patient-list-view {
  padding: 56px 112px 178px 112px;
}

.patient-list-view-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 99rem;
}
