/* nodata.component.scss
*
*  This .css file contains code for the styling of No Data Available Error State View
*/

.graph {
  width: 266px;
  height: 96px;
  margin: 0 102px 13px 100px;
  object-fit: contain;
  align-items: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.divider {
  width: 468px;
  height: 1px;
  margin: 10px -85px 24px;
  border: solid 1px var(--cool-gray-20-v-2-1);
}

.no-data-available {
  width: 244px;
  height: 36px;
  margin: 24px 113px 0 12px;
  font-family: Inter;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: var(--gray-100-v-2-1);
  text-align: center;
}

.here-might-be-why {
  width: 150px;
  height: 16px;
  margin: 13px 138px 11px 13px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.32px;
  text-align: center;
  color: var(--gray-60-v-2-1);
}

.bx--list--unordered > .bx--list__item::before {
  content: '\002192';
  width: 13px;
  height: 13px;
  font-size: 11px;
  padding: 2px 10px 3px 10px;
  margin-right: 50px;
  font-family: Sentinel SSm A, Sentinel SSm B, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
}

.bx--list__item {
  width: 300px;
  height: 32px;
  margin: 2px 99px 0px 28px;
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.32px;
  display: flex;
  color: var(--gray-60-v-2-1);
}

.bx--list__item span {
  margin-left: 10px;
  text-align: left;
}

/* 
* EOF
*/
