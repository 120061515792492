.bx--tabs--scrollable {
  margin-top: 52px;
  margin-right: auto;
  margin-left: auto;
  max-width: 99rem;
}

.bx--tab-content {
  margin-right: auto;
  margin-left: auto;
  max-width: 99rem;
  padding: 0;
  text-align: left;
  position: relative;
}

.bx--tab-content:focus {
  outline: none;
  outline-offset: 0;
}

div.bx--tabs--scrollable {
  margin-bottom: 50px;
}

#dashboard-tab,
#risk-details-tab,
#therapy-details-tab {
  width: 239px;
}

//outline won't appear
#dashboard:focus,
#risk-details:focus,
#therapy-details:focus {
  outline-color: transparent;
}

//outline around tab contents won't appear
.bx--tabs--scrollable .bx--tabs--scrollable__nav-link:focus,
.bx--tabs--scrollable .bx--tabs--scrollable__nav-link:active,
.bx--tabs--scrollable .bx--tabs--scrollable__nav-link:hover,
.bx--tabs--scrollable .bx--tabs--scrollable__nav-link {
  outline: none;
  outline-offset: 0;
}

.risk-calculated-link {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

//active/selected color
li button[aria-selected='true'] {
  border-color: #0f62fe !important; //override default color
}

//inactive color
li button[aria-selected='false'] {
  border-color: #d0e2ff !important; //override default color
}

//hover color, only affect inactive tabs
li button:hover[aria-selected='false'] {
  border-color: #78a9ff !important; //override default color
}

//dashboard tab content
#dashboard__panel {
  padding: 0;
}

.dashboard-tile-header {
  margin-bottom: 20px;
  font-size: 16px;
  font-family: Inter;
  color: var(--gray-70-v-2-1);
  text-align: left;
}

//therapy-details tab content
#therapy-details__panel {
  padding: 0;
}

.maintitle {
  font-size: 22px;
}

.subtitle {
  width: 162px;
  height: 28px;
  margin: 50px 926px 20px 0;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #161616;
  text-align: left;
}
