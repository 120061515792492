table {
  text-align: left;
  font-stretch: normal;
  font-style: normal;
}

th {
  height: 18px;
  padding: 15px 0 30px 16px;
  line-height: 1.29;
  letter-spacing: 0.16px;
  font-size: 14px;
  font-family: $inter;
  background-color: var(--white);
  color: var(--gray-100-v-2-1);
  box-sizing: content-box;
  font-weight: 600;
  border-bottom: 1px solid var(--cool-gray-80-v-2-1);
}

td.text-content {
  color: var(--gray-70-v-2-1);
  height: 40px;
  padding: 16px 0 30px 16px;
  line-height: 1.29;
  letter-spacing: 0.16px;
  font-size: 14px;
  font-family: $inter;
  font-weight: normal;
  background-color: var(--white);
  box-sizing: content-box;
  border-top: 1px solid var(--cool-gray-20-v-2-1);
}
