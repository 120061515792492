.histogram-tile-component {
  padding: 0px;
  margin-bottom: 53px;

  .risk-calculated-link {
    top: 37px;
  }
}

.risk-details-profile {
  margin-bottom: 14px;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: var(--gray_100_v2.1);
  font-family: 'Inter';
}

.risk-details-profile-sentence {
  margin-bottom: 20px;
  text-align: left;
  font-size: 16px;
  font-family: 'Inter';
  color: var(--gray-70-v-2-1);
}

.histogram-tile {
  padding: 0px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: var(--white);
  display: flex;
}

span.risk-score-span {
  display: flex;
  width: 245px;
  height: 18px;
  margin: 0 396px 42px 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
  color: var(--cool-gray-60-v-2-1);
}

.risk-details-card-histogram {
  margin-top: 42px;
  margin-left: 48px;
  width: 55%;
  height: 595px;
}

.card-information-on-right {
  margin: 109px 49px 155px;
  width: 45%;
  text-align: left;
}

//the colors based on each risk level, should change depending on patient info
.risk-details-risk-score {
  margin-bottom: 18px;
  font-size: 16px;
  font-family: 'IBM Plex Mono';
  font-weight: normal;
}

.risk-score.Extreme-color {
  color: #ce001f;
}

.risk-score.High-color {
  color: #e66d18;
}

.risk-score.Low-color {
  color: #229338;
}

.risk-details-percentile-box {
  margin-bottom: 24px;
  display: inline-block;
  height: 33px;
  border: solid 1px var(--cool-gray-20-v-2-1);
  border-left-width: 2px;
}

.risk-details-percentile-box.Extreme-color {
  border-left-color: #ce001f;
}

.risk-details-percentile-box.High-color {
  border-left-color: #e66d18;
}

.risk-details-percentile-box.Low-color {
  border-left-color: #229338;
}

.risk-percentile-box-text {
  padding: 8px 16px 8px 16px;
  height: 16px;
  font-size: 12px;
  font-family: 'Inter';
  color: #171717;
}

.percentile-ordinal {
  font-size: 8px;
  color: #171717;
}

.card-information-on-right p {
  margin-bottom: 77px;
  font-size: 14px;
  font-family: 'Inter';
  color: var(--gray-100-v-2-1);
}

.relapse-rate-title {
  margin-bottom: 22px;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: normal;
  color: var(--cool-gray-60-v-2-1);
}

.relapse-rate-year {
  margin-bottom: 12px;
  font-size: 20px;
  font-family: 'IBM Plex Mono';
  font-weight: normal;
  color: var(--gray-100-v-2-1);
}

#relapse-rate-1,
#relapse-rate-2,
#relapse-rate-5 {
  margin-right: 14px;
  font-weight: normal;
}

#relapse-rate-1 {
  color: #229338;
}

#relapse-rate-2 {
  color: #d0834d;
}

#relapse-rate-5 {
  color: #ce001f;
}

#relapse-5-years {
  margin-bottom: 0px;
}
