table {
  width: 100%;
}

.patient-name {
  width: 20%;
}

.patient-MRID {
  width: 30%;
}

.patient-age {
  width: 10%;
}

.patient-disease {
  width: 30%;
}

.patient-btn-col {
  width: 20%;
}

td.btn-view,
td.tag-view {
  padding: 17px 0 15px 0;
  border-top: 1px solid var(--cool-gray-20-v-2-1);
}

td .patient-btn:enabled {
  width: 128px;
  height: 32px;
  min-height: 32px;
  padding: 0 0 0 16px;
  margin-left: 10px;
  margin-right: 16px;
  background-color: var(--blue-70-v-2-1);
  color: var(--gray-10-v-2-1);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.16px;
}

td .patient-btn:hover {
  background-color: var(--blue-90-v-2-1);
}

td .patient-btn:active {
  background-color: var(--blue-80-v-2-1);
}

td .patient-tag {
  width: 128px;
  height: 24px;
  padding: 0 8px;
  margin: 10px 16px 0 10px;
  border-radius: 12px;
  background-color: var(--red-20-v-2-1);
  color: var(--gray-100-v-2-1);
}
