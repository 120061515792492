// Gives Median field a dashed line in legend
div.bx--cc--legend div.legend-item.additional svg.icon {
  width: 11px !important; // !important to override Carbon inline styling
  height: 11px !important; // !important to override Carbon inline styling
  background: url(./../../assets/dashed-line.svg) no-repeat center;
  background-size: 100% 100%;
}

// Makes points all 6px diameter
// and also makes it so median threshold does not change point diameter
.bx--cc--scatter circle.dot {
  stroke-width: 0;
}

// Removes default padding for card from Carbon
.bx--tab-content {
  padding: 0;
}

// Makes axis labels not bolded
.bx--cc--axes g.axis .axis-title {
  font-weight: normal;
}

// Makes all points same size regardless of if above/below median threshold
.bx--cc--scatter circle.dot.threshold-anomaly {
  stroke-width: 0;
}

// Removes pop up on hover over median line
.bx--cc--threshold--label {
  opacity: 0;
}

// Makes it so threshold line does not grow wider when mouse hover over
.bx--cc--threshold line.threshold-line.active {
  stroke-width: 0;
  stroke-width: 1;
  stroke-dasharray: 4;
  pointer-events: none;
}

// Makes it so mouse cursor does not change when hover over threshold
.bx--cc--threshold rect.threshold-hoverable-area {
  cursor: default;
}

// Styling for title with "Survival Curves" as text
span.graphTitleSpan {
  display: flex;
  padding: 0px 0px 32px 0px;
  color: var(--cool-gray-60-v-2-1);
  font-size: 14px;
}

// Styling for title above the curve card
h4.survival-curve-title {
  text-align: left;
  padding: 0px 0px 20px 0px;
  font-weight: 600;
  font-family: Inter;
}

// Spaces legend to be more even with Y axis
div.bx--cc--legend {
  padding-left: 48px;
}

// Adds spacing between legend items
div.legend-item {
  padding: 0px 10px 0px 0px;
}

// Styling for the dropshadow card
.bx--tile.card-with-shadow {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: var(--white);
  margin-bottom: 56px;
  padding: 40px 48px 48px 48px;
}

// All items until next comment related to the info box
// displayed next to the graph (particularly its spacing)
p.patient-name {
  padding-bottom: 14px;
  font-size: 14px;
  font-family: Inter;
  letter-spacing: 0.16px;
  font-weight: 600;
}

p.patient-risk {
  padding-bottom: 19px;
  font-size: 14px;
  letter-spacing: 0.16px;
}

p.info-paragraph {
  font-size: 12px;
}

span.mpfs {
  font-size: 12px;
  font-weight: 600;
  margin: 0px 0px 0px 16px;
}

span.months {
  text-align: left;
  font-size: 12px;
  margin: 0px 16px 0px 0px;
  font-family: Inter;
  font-weight: normal;
}

.months-container {
  display: inline-block;
  padding: 8px 0px 8px 0px;
  border: solid 1px var(--cool-gray-20-v-2-1);
  height: 34px;
  margin-bottom: 13px;
}

.content-block {
  padding: 19px 0px 0px 48px;
  flex-basis: 30%;
  display: block;
  text-align: left;
}

// The following classes make the Risk Level text color
// and the month box left highlight color responsive to patient info
.Extreme-color {
  color: #f53345;
}

.High-color {
  color: #d0834d;
}

.Low-color {
  color: #39b552;
}

.months-container.Extreme-left-border {
  border-left: solid 2px #f53345;
}

.months-container.High-left-border {
  border-left: solid 2px #d0834d;
}

.months-container.Low-left-border {
  border-left: solid 2px #39b552;
}

// Used to establish spacing between chart and info box
.chart-and-content {
  display: flex;
}
