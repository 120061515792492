// TODO: SYG-### - temporary until we implement proper header to main content spacing
// https://react.carbondesignsystem.com/?path=/story/components-ui-shell--header-base-w-navigation-actions-and-side-nav

.page-content-without-header-beadcrumbs {
  margin: 0 112px 112px;
}

.patient-heading {
  margin-right: auto;
  margin-left: auto;
  max-width: 99rem;
  color: #161616;
  font-size: 28px;
  font-weight: normal;
  font-family: Inter;
  text-align: left;
}
