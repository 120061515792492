.risk-margin {
  margin-left: 0;
  margin-bottom: 56px;
  .widget-title {
    width: 331px;
    height: 27px;
    margin: 0 757px 21px 0;
    font-size: 20px;
    font-family: Inter;
    font-weight: 600;
    line-height: 1.4;
    display: table;
    color: var(--gray-100-v-2-1);
    text-align: left;
  }

  .sub-heading {
    width: 432px;
    margin: 0 757px 21px 0;
    font-family: Inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 0.16px;
    color: var(--gray-100-v-2-1);
    text-align: left;
  }
  .not-present-tags {
    background-color: var(--cool-gray-20-v-2-1);
    margin-bottom: 24px;
    margin-right: 15px;
  }
  .tag-present {
    margin-left: 62px;
    display: table;
    background-color: var(--blue-20-v-2-1);
    margin-bottom: 24px;
    margin-top: 16px;
  }
  .flex-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: space-evenly;
    width: 152px;
    td {
      width: 36px;
      height: 16px;
    }
  }

  .align {
    text-align: left;
  }

  .padding-uniq {
    padding: 0 !important;
  }
  .bold {
    font-weight: 600;
    font-size: 16px;
    margin-top: 12px;
  }
  .yr {
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: 0.32px;
    color: var(--gray-70-v-2-1);
    margin-top: 6px;
  }

  .risk-card {
    height: 192px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    background-color: var(--white);
    th {
      color: var(--gray-70-v-2-1);
      font-family: Inter;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.16px;
      border-bottom: 1px;
      margin-bottom: 0;
      padding: 0 0 0 15px;
    }
    tr {
      margin-left: 15px;
      margin-right: 15px;
      padding: 0 0 0 15px;
      margin-top: 0;
    }
    td {
      vertical-align: text-top;
      padding: 0 0 0 15px;
    }
    table {
      margin-left: 48px;
      margin-right: 128px;
      width: 80%;
    }
  }
}
