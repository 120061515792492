/* spinner.component.scss
*
*  This .css file contains code for the styling of Loading State View
*/

.loading-large {
  width: 88px;
  height: 88px;
  margin: 0 27px 10px;
  padding: 250px 15px 15px 13px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-items: center;

  .spinner-css {
    width: 60px;
    height: 60px;
    align-items: center;
    display: flex;
  }

  .retrieving-patient-d {
    width: 142px;
    height: 16px;
    margin: 25px -40px 0px;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.32px;
    text-align: center;
    color: var(--gray-70-v-2-1);
  }
}

/* 
* EOF
*/
