@import 'global';

Button {
  font-family: Inter, sans-serif;
  width: 178px;
  height: 48px;
  padding: 0 0 0 16px;
}

.bx--btn--secondary {
  width: 195px;
  height: 48px;
  padding: 0 0 0 16px;
  background-color: var(--cool-gray-80-v-2-1);
}
