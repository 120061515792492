.calc-modal {
  width: 1100px;
  left: calc(50% - 550px);

  .bx--modal-container {
    width: 1000px;
  }

  .bx--modal-content {
    width: 1000px;
  }

  .calc-modal-container {
    width: 100%;
    height: 100%;
    align-items: center;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly;

    .calc-modal-content-box {
      width: 200px;
      height: 176px;
      border: 1px solid var(--blue-40-v-2-1);

      .calc-modal-icon {
        color: var(--blue-40-v-2-1);
        vertical-align: middle;
        margin-right: 5px;
      }

      .user-profile {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        margin-right: 0;
        margin-left: 5px;
      }

      .calc-modal-title {
        font-size: 14px;
        font-weight: 600;
        padding-top: 20px;
        vertical-align: middle;
      }

      .calc-modal-text {
        font-size: 12px;
        color: var(--gray-70-v-2-1);
        padding: 10px 20px 20px 20px;
      }
    }
  }
}
